<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import numeral from "numeral";
import * as echarts from "echarts";
require("echarts/theme/macarons"); // echarts theme
const animationDuration = 2000;
export default {
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    barWidth: {
      type: String,
      default: "30px",
    },
    height: {
      type: String,
      default: "300px",
    },
    label: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    legend: {
      type: Array,
      default: () => [],
    },
    color: {
      type: Array,
      default: () => [],
    },
    fetchChart: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    fetchChart() {
      if (!this.chart) {
        return;
      }
      this.chart.dispose();
      this.chart = null;
      setTimeout(() => {
        this.initChart();
      }, 400);
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");
      this.chart.setOption({
        toolbox: {
          show: true,
        },
        legend: {
          data: this.legend,
        },
        tooltip: {
          textStyle: {
            fontSize: 16,
          },
          trigger: "axis",
          textStyle: {
            color: "#fff",
            fontSize: 16,
          },
          axisPointer: {
            type: "shadow",
          },
          // formatter: "{b} <br>{a}: {c}",
          formatter: (params) => {
            params.sort(function (a, b) {
              // Sort params by value size first
              return parseInt(b.value) - parseInt(a.value);
            });
            var output = params[0].name + "<br/>";
            for (var i = 0; i < params.length; i++) {
              output +=
                params[i].marker +
                params[i].seriesName +
                (params[i].seriesName ? ": " : "") +
                params[i].value.toLocaleString();
              output += "<span> ราย </span>";
              output +=
                "<span class='pd-l-6'>" +
                this.totalPercent(params[i].value, this.data[0].total) +
                "%</span>";
              if (i != params.length - 1) {
                // Append a <br/> tag if not last in loop
                output += "<br/>";
              }
            }
            return output;
          },
        },
        textStyle: {
          fontFamily: "DBHeavent",
          fontSize: 18,
          color: "#000",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.label,
            axisLine: {
              lineStyle: {
                color: "rgba(0, 0, 0, 1)",
              },
            },
            axisLabel: {
              fontSize: 18,
            },
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              fontSize: 18,
              formatter: (params) => {
                let output = "";
                let findDot = params.toString().includes(".");
                if (!findDot) {
                  output = params;
                }
                return output;
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: this.barWidth,
            name: this.data[0].name,
            data: [
              {
                value: this.data[0].data[0],
                itemStyle: {
                  color: this.color[0],
                },
              },

              {
                value: this.data[0].data[1],
                itemStyle: {
                  color: this.color[1],
                },
              },
              {
                value: this.data[0].data[2],
                itemStyle: {
                  color: this.color[2],
                },
              },
              ,
            ],
          },
        ],
      });
    },
  },
};
</script>