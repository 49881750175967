<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import * as echarts from "echarts";
require("echarts/theme/macarons"); // echarts theme
const animationDuration = 2000;
export default {
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "300px",
    },
    label: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    color: {
      type: Array,
      default: () => [],
    },
    fetchChart: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    fetchChart() {
      if (!this.chart) {
        return;
      }
      this.chart.dispose();
      this.chart = null;
      setTimeout(() => {
        this.initChart();
      }, 400);
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");
      this.chart.setOption({
        tooltip: {
          trigger: "item",
          textStyle: {
            color: "#fff",
          },
        },

        textStyle: {
          fontFamily: "DBHeavent",
          fontSize: 18,
        },
        series: [
          {
            color: this.color,
            type: "pie",
            radius: ["50%", "85%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "start",
            },
            center: ["50%", "50%"],
            data: this.data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            tooltip: {
              textStyle: {
                fontSize: 16,
              },
              formatter: (params) => {
                var output = params.marker + params.name;
                output +=
                  "<span class='pd-l-7'>" +
                  params.value +
                  " ราย</span> ";
                output +=
                  "<span class='pd-l-6'>" +
                  params.percent +
                  "%</span>";
                return output;
              },
              position: function (pos, params, dom, rect, size) {
                // tooltip will be fixed on the right if mouse hovering on the left,
                // and on the left if hovering on the right.
                var obj = { top: 60 };
                obj[["left", "right"][+(pos[0] < size.viewSize[0] / 2)]] = 5;
                return obj;
              },
              //  position: 'bottom',
            },
          },
        ],
      });
    },
  },
};
</script>